import React, { Component } from "react"
import * as ReactDOMClient from "react-dom/client"


class Hero extends Component {
    render() {
        const hero = (
            <div className="hero">
                <div className="rotating-hero-text">
                    <Link to="/film"> 
                        <div className="hero-text">Film</div>
                    </Link>
                    <div className="hero-text">Animation</div>
                    <div className="hero-text">Code</div>
                    <div className="hero-text">Motion Graphics</div>
                </div>
            </div>
        );
        return hero
    }
}

export default Hero
