import React, { useRef, useState, useEffect } from 'react';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

const LazyIFrame = ({ src, title, ...props }) => {
  const iframeRef = useRef();
  const [hasLoaded, setHasLoaded] = useState(false);
  const isIntersecting = useIntersectionObserver(iframeRef, {
    rootMargin: '0px',
    threshold: 0.1,
  });

  useEffect(() => {
    if (isIntersecting && !hasLoaded) {
      setHasLoaded(true);
    }
  }, [isIntersecting, hasLoaded]);

  return (
    <div ref={iframeRef} style={{ minHeight: '200px' }}>
      {hasLoaded ? (
        <iframe src={src} title={title} {...props}></iframe>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default LazyIFrame;
