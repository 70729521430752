import React, { useState, useEffect } from 'react';

const TypingCodeEffect = () => {
  const [displayText, setDisplayText] = useState({
    leftBracket: false,
    C: false,
    o: false,
    d: false,
    e: false,
    slash: false,
    rightBracket: false
  });

  useEffect(() => {
    const timeouts = [];

    // Timing for each part of the code to type out sequentially
    timeouts.push(setTimeout(() => setDisplayText((prev) => ({ ...prev, leftBracket: true })), 0)); // `<`
    timeouts.push(setTimeout(() => setDisplayText((prev) => ({ ...prev, C: true })), 250));          // `C`
    timeouts.push(setTimeout(() => setDisplayText((prev) => ({ ...prev, o: true })), 500));          // `o`
    timeouts.push(setTimeout(() => setDisplayText((prev) => ({ ...prev, d: true })), 750));          // `d`
    timeouts.push(setTimeout(() => setDisplayText((prev) => ({ ...prev, e: true })), 900));          // `e`
    timeouts.push(setTimeout(() => setDisplayText((prev) => ({ ...prev, slash: true })), 1050));      // `/`
    timeouts.push(setTimeout(() => setDisplayText((prev) => ({ ...prev, rightBracket: true })), 1200)); // `>`

    // Clear timeouts on cleanup
    return () => timeouts.forEach((timeout) => clearTimeout(timeout));
  }, []);

  return (
    <h1 className="highlighted-code">
      <span className="brackets left-bracket">{displayText.leftBracket && "<"}</span>
      <span className="tag">
        {displayText.C && "C"}
        {displayText.o && "o"}
        {displayText.d && "d"}
        {displayText.e && "e"}
      </span>
      <span className="slash">{displayText.slash && " /"}</span>
      <span className="brackets right-bracket">{displayText.rightBracket && ">"}</span>
    </h1>
  );
};

export default TypingCodeEffect;