import React, { Component } from "react"
import Header from "./header.jsx"

import RivePlayPauseButton from "./components/rive-play-pause-btn.jsx"

const filmVideoFile = new URL("./video/Reel 2024.mp4", import.meta.url)
const filmVideoThumbnail = new URL("./video/Reel 2024 - Thumbnail.jpg", import.meta.url)

class Film extends Component {
    constructor(props) {
        super(props)
        // isPlaying
        this.state = {
            isPlaying: true, // Add state to manage play/pause
        }
        
        // Bind methods
        this.handlePlayPause = this.handlePlayPause.bind(this)

        // Create a ref to access the video element
        this.videoRef = React.createRef()
    }

    handlePlayPause = () => {
        const video = this.videoRef.current; // Access video element via ref
        const { isPlaying } = this.state; // Destructure state

        if (isPlaying) {
            video.pause();
        } else {
            video.play();
        }

        // Toggle the state of isPlaying
        this.setState({ isPlaying: !isPlaying });
    }

    render() {
        const contactForm = (
            <div>
                <Header />
                <div className= "page-body product film">
                    <div className="title-text">
                        <h1>Film</h1>
                    </div>
                    <div className="hero-product-video">
                        <div className="product-video-container">
                            <video 
                                ref={this.videoRef} 
                                src={filmVideoFile} 
                                muted 
                                autoPlay 
                                loop
                                // controls={true}
                                playsInline 
                                type="video/mp4" 
                                poster={filmVideoThumbnail}>
                            </video>
                            <RivePlayPauseButton isPlaying={this.state.isPlaying} handlePlayPause={this.handlePlayPause} />
                        </div>
                    </div>
                    <div className="centered-banner-text">
                        More Coming Soon...
                    </div>
                </div>
            </div>
        )
        return contactForm
    }
}

export default Film