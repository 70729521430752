import React, { Component } from "react"
import { useState } from "react/cjs/react.production.min.js"
import Header from "./header.jsx"


const foldedStyle = "folded"
const expandedStyle = "expanded"

class Feature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false
        }
    }

    handleClick = (e) => {
        if (this.state.isExpanded) {
            this.setState({
                isExpanded: false
            })
        } else {
            this.setState({
                isExpanded: true
            })
        }
    }

    getImages() {
        var imgs = []
        this.props.images.forEach((item,index)=>{
            console.log(this.props.images.length)
            imgs.push( <img className="feature-image" src={item} alt="Image"/> )
        })
        return imgs
    }

    render() {
        const myElement = (
            <div className={"feature " + (this.state.isExpanded ? expandedStyle : foldedStyle)}>
                <div className={"feature-title " + (this.state.isExpanded ? expandedStyle : foldedStyle)} onClick={this.handleClick}>
                    <img className="feature-client-logo" src={this.props.logo} alt="Client Logo"/>
                    <div>{this.props.title}</div>
                    <div className={"feature-carrot " + (this.state.isExpanded ? expandedStyle : foldedStyle)}>^</div>
                </div>
                <div className={"feature-body " + (this.state.isExpanded ? expandedStyle : foldedStyle)}>
                    <p>{this.props.subtitle}</p>
                    <p>{this.props.description}</p>
                    <div className="feature-images">
                        {this.getImages()}
                    </div>
                </div>
            </div>
        )
        return myElement
    }
}

export default Feature