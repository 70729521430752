import React, { Component } from "react"
import { BrowserRouter, Route, Routes, Switch, Link, Outlet, NavLink } from "react-router-dom"
import LazyIFrame from "./components/LazyIFrame"

// const videoFile = new URL("./video/Reel 2022.mp4", import.meta.url)
// const thumbnail = new URL("./video/Reel 2022 Thumbnail.png", import.meta.url)

class GalleryItem extends Component {
    getVideoFile() {
        if (this.props.videoFilePath != null){
            const videoType = this.getVideoType()
            if (videoType == "youtube" || videoType == "vimeo") {
                // "?autoplay=1&mute=1"
                // console.log("Is Youtube video")
                return this.props.videoFilePath
            } else if (videoType == "local") {
                // console.log("Not Youtube")
                return new URL(this.props.videoFilePath, import.meta.url)
            }
            return this.props.videoFilePath
        }
        return videoFile
    }

    getThumbnail() {
        if (this.props.thumbnailPath != null) {
            return this.props.thumbnailPath
        }
        return ""
    }

    getTitle() {
        if (this.props.title != null) {
            return this.props.title
        }
        return ""
    }

    getMyPosition() {
        if (this.props.myPosition != null) {
            return this.props.myPosition
        }
         return ""
    }

    getClickThroughText() {
        if (this.props.clickThroughText != null) {
            return this.props.clickThroughText
        }
        return ""
    }

    getClickThroughLink() {
        if (this.props.clickThroughLink != null) {
            return this.props.clickThroughLink
        }
        return ""
    }

    getVideoType() {
        if (this.props.videoType != null) {
            if (this.props.videoType == "youtube") {
                return "youtube"
            }
            if (this.props.videoType == "local") {
                return "local"
            }
            if (this.props.videoType == "vimeo") {
                return "vimeo"
            }
        }

        return "local"
    }

    toggleControls() {
        var video = document.getElementsByClassName("gallery-video")[0]
        if (video.hasAttribute("controls")) {
           video.removeAttribute("controls")   
        } else {
           video.setAttribute("controls","controls")   
        }
      }

    render() {
        var myElement = (
            <div className="galleryItem">
                <div className="gallery-stack">
                    <video className="gallery-video" poster={this.getThumbnail()} onMouseOver={event => event.target.play()}  muted loop playsInline>
                        <source src={this.getVideoFile()} type="video/mp4"></source>
                    </video>
                    <div className="gallery-caption">
                        <div className="galleryMyPosition">
                            <span className="gallery-role">Role:</span>{this.getMyPosition()}
                        </div>

                        {this.getClickThroughText() != "" && (
                            <div className="gallery-clickThrough">
                                <Link to={this.getClickThroughLink()}>
                                    <span>
                                        {this.getClickThroughText()}
                                    </span>
                                </Link>
                                {/* <a href={this.getClickThroughLink()} target="_blank" rel="noreferrer">
                                    <span>
                                        {this.getClickThroughText()}
                                    </span>
                                </a> */}
                            </div>
                        )}
                    </div>
                </div>
                <div className="gallery-caption">
                    <h2 className="gallery-title">
                        {this.getTitle()}
                    </h2>
                </div>
            </div>
        )

        // <iframe width="560" height="315" src="https://www.youtube.com/embed/vE5NYrNrog0" title="Dusty" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        if (this.getVideoType() != "local") {
            myElement = (
                <div className="galleryItem">
                    <div className="gallery-stack">
                        {/* <iframe className="gallery-video lazyload" src={this.getVideoFile()}  frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                        </iframe> */}
                        {/* <LazyIFrame className="gallery-video lazyload" src={this.getVideoFile()}  frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen> */}
                        {/* </LazyIFrame> */}
                        <LazyIFrame
                            className="gallery-video lazyload"
                            src={this.getVideoFile()}
                            title="Example Video"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <div className="galleryMyPosition">
                            <span className="gallery-role">Role:</span>{this.getMyPosition()}
                        </div>
                    </div>
                </div>
            )
        }
        return myElement
    }
}

export default GalleryItem