import React, { Component } from "react"

import Header from "./header.jsx"


class Error extends Component {
    render() {
        const home = (
            <div>
                {/* <Header /> */}
                <h1 className="error">No Page Found by that Name</h1>
            </div>
        )
        return home
    }
}

export default Error