import React, { Component } from "react"
import Header from "./header.jsx"

class MotionTemplates extends Component {
    render() {
        const component = (
            <div>
                <Header />
                <div className="page-body product motion-templates">
                    <div className="title-text">
                        <h1>Motion Templates</h1>
                    </div>
                    <div className="centered-banner-text">
                        More Coming Soon...
                    </div>
                </div>
            </div>
        )
        return component
    }
}

export default MotionTemplates