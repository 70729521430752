// import React, { useRef, useEffect } from 'react';
// import '@dotlottie/player-component';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import logoAnimationData from "./lottie/Variable Studio - Logo - Lottie - 07.lottie"

function HeroAnimation() {
  return (
    <div>
        <div className="logo-animation">
        <DotLottieReact
            src={logoAnimationData}
            autoplay
        />
        {/* <dotlottie-player
            src="https://lottie.host/7af6b564-186e-4978-abad-75a448193170/tzakwAukxY.lottie"
            autoplay
            /> */}
        </div>
    </div>
  );
}

export default HeroAnimation;