import React, { Component } from "react";
import RivePlayPauseButton from "./rive-play-pause-btn.jsx";

// Array of video segment paths
const videoSegments = [
    new URL("../video/Reel 2024 - Segmented/segment_01.mp4", import.meta.url),
    new URL("../video/Reel 2024 - Segmented/segment_02.mp4", import.meta.url),
    new URL("../video/Reel 2024 - Segmented/segment_03.mp4", import.meta.url)
];
const thumbnail = new URL("../video/Reel 2024 - Thumbnail.jpg", import.meta.url);

class SegmentedVideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSegmentIndex: 0, // Track which segment is playing
            isPlaying: true, // Manage play/pause state
        };

        // Bind methods
        this.handlePlayPause = this.handlePlayPause.bind(this);
        this.playNextSegment = this.playNextSegment.bind(this);

        // Create a ref to access the video element
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        const video = this.videoRef.current;

        // Listen for video end to trigger next segment
        video.addEventListener("ended", this.playNextSegment);
        
        // Load and play the first segment
        this.loadSegment();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentSegmentIndex !== this.state.currentSegmentIndex) {
            this.loadSegment();
        }
    }

    componentWillUnmount() {
        const video = this.videoRef.current;

        // Clean up event listener when component unmounts
        video.removeEventListener("ended", this.playNextSegment);
    }

    // Load the current video segment
    loadSegment() {
        const video = this.videoRef.current;
        const { currentSegmentIndex } = this.state;

        // Set new source and play the video
        video.src = videoSegments[currentSegmentIndex];
        video.load(); // Required to reload the video source
        video.play();
    }

    // Play the next video segment when the current one ends
    playNextSegment() {
        this.setState((prevState) => {
            const nextIndex = (prevState.currentSegmentIndex + 1) % videoSegments.length;
            return { currentSegmentIndex: nextIndex };
        });
    }

    // Play/Pause video
    handlePlayPause() {
        const video = this.videoRef.current;
        const { isPlaying } = this.state;

        if (isPlaying) {
            video.pause();
        } else {
            video.play();
        }

        // Toggle the play/pause state
        this.setState({ isPlaying: !isPlaying });
    }

    render() {
        const { isPlaying } = this.state;

        return (
            <div className="hero-video-blur">
                <div className="hero-video-player">
                    <video
                        ref={this.videoRef}
                        width="1920px"
                        height="1080px"
                        className="video-bg"
                        id="vid"
                        poster={thumbnail}
                        muted
                        playsInline
                    >
                        {/* Source will be updated dynamically */}
                    </video>

                    {/* Play/Pause Button */}
                    <RivePlayPauseButton isPlaying={isPlaying} handlePlayPause={this.handlePlayPause} />
                </div>
            </div>
        );
    }
}

export default SegmentedVideoPlayer;