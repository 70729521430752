import React, { Component } from "react"
import Header from "./header.jsx"

class Animation extends Component {
    render() {
        const component = (
            <div>
                <Header />
                <div className="page-body product animation">
                    <div className="title-text">
                        <h1>Animation</h1>
                    </div>
                    <div className="centered-banner-text">
                        More Coming Soon...
                    </div>
                </div>
            </div>
        )
        return component
    }
}

export default Animation