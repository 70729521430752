import React, { Component } from "react"
import { BrowserRouter, Route, Routes, Switch, Link, Outlet, NavLink } from "react-router-dom"
import Header from "./header.jsx"
import Gallery from "./gallery.jsx"
import Feature from "./feature.jsx"


const iveyMediaLogo = new URL("./img/client-logos/iveyMedia-white.png", import.meta.url)
const iaaplogo = new URL("./featured-projects/IAAP-IveyMedia/IAAP_Logo.gif", import.meta.url)
const iaapLessonTitle = new URL("./featured-projects/IAAP-IveyMedia/IAAP_Lesson_Title.gif", import.meta.url)
const iaapLowerThird = new URL("./featured-projects/IAAP-IveyMedia/Lower_Third_for_Website_Example.gif", import.meta.url)
const iaapEgpOne = new URL("./featured-projects/IAAP-IveyMedia/iaap-egp-01.png", import.meta.url)
const iaapEgpTwo = new URL("./featured-projects/IAAP-IveyMedia/iaap-egp-02.png", import.meta.url)
const iaapLowerThirdwireframe= new URL("./featured-projects/IAAP-IveyMedia/iaap-lowerthird-wireframe.png", import.meta.url)
var iveyMediaImageList = [iaaplogo, iaapLessonTitle, iaapLowerThird, iaapLowerThirdwireframe, iaapEgpOne, iaapEgpTwo]


class Work extends Component {
    render() {
        const myElement = (
            <div className="work">
                <Header />
                <h1 class="work-title">Work</h1>
                {/* <h2>Featured</h2> */}
                {/* <Feature logo={iveyMediaLogo} title='Ivey Media: "I Am a Podcaster" Course' subtitle='We worked with Ivey Media to create a custom motion graphics package for their podcasting course.' description="We created 3 animations for the Ivey Media podcasting course: an intro title, lesson titles, and lower thirds. We used coding to accommodate texts of varying length, to randomly generate colors of lesson titles, and to control vertical centering of elements if added or removed." images={iveyMediaImageList}></Feature> */}
                {/* <p className="comingSoon">A gallery with our work is coming soon. For now, checkout the reel that is playing on the <Link to="/">home page</Link>.</p> */}
                <div className="work-gallery-title">Gallery</div>
                <Gallery>

                </Gallery>
            </div>
        )
        return myElement
    }
}

export default Work