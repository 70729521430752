import React, { Component } from "react"
import Header from "./header.jsx"
import Rive from "@rive-app/react-canvas";

const logoAnimationsVideo = new URL("./video/Logo Animations 2024 - v02.mp4", import.meta.url)
const logoAnimationsThumbnail = new URL("./video/Logo Animations 2024 - v02 - Thumbnail.jpeg", import.meta.url)
const plusAnimation = new URL("./rive/plus.riv", import.meta.url)

class LogoAnimations extends Component {

    render() {
        const logoAnimations = (
            <div className="logo-animations">
                <Header />
                <div className="page-body">
                    <div className="title-text">
                        <h1>Logo Animations</h1>
                    </div>
                    <div className="hero-logo-animation-video">
                        <video src={logoAnimationsVideo} muted autoPlay loop playsInline type="video/mp4" poster={logoAnimationsThumbnail}></video>
                    </div>
                    <div className="description-text">
                        <section>
                            <h2>Elevate Your Brand with Dynamic Logo Animation</h2>
                            <p>In today’s digital age, capturing attention is everything. At Variable Studio, we bring your brand to life with stunning logo animations that make a lasting impression. Motion adds a new dimension to your logo, making it more engaging and memorable.</p>
                            <h3>Why Animate Your Logo?</h3>
                            <ul>
                                <li><strong>Stand Out Across Platforms:</strong> Animated logos grab attention and boost engagement on social media, in promotional videos, and long-form content. Make your brand more visible in the crowded digital landscape.</li>
                                <li><strong>Tell Your Brand's Story:</strong> The motion of your logo tells a unique story about your brand. Beyond the shape and characters, motion brings your company's personality to life, creating a deeper connection with your audience.</li>
                                <li><strong>Responsive and Interactive:</strong> Using web-based solutions, like <span className="tooltip">Lottie<span className="tooltiptext">Lottie is a library that renders animations in real-time, allowing for high-quality, scalable, and interactive animations on websites and apps.</span></span> and <span className="tooltip rive-tooltip">Rive<span className="tooltiptext">Rive is a powerful tool for creating and integrating real-time interactive animations into websites and apps, enhancing user engagement.</span></span>, can offer responsive and interactive animations that adapt seamlessly to different devices and screen sizes. Engage your customers with dynamic animations that respond to their interactions in real-time.</li>
                            </ul>
                            
                            <p>You provide the logo. We provide the animation.</p>
                        </section>
                    </div>
                    <h2>Packages</h2>
                    <div className="logo-animation-packages">
                        <div className="logo-animation-starter logo-animation-package">
                            <div className="package-title">
                                <h2>Starter</h2>
                                <div className="logo-animation-price">$1,500</div>
                            </div>
                            <ul>
                                <li>Logo Animation</li>
                                <li>Video exports in .mov, .mp4, and ProRes 4444 Transparent</li>
                                <li>3 Rounds of Revisions</li>
                            </ul>
                        </div>
                        <div className="logo-animation-plus logo-animation-package">
                            <div className="package-title">
                                <div className="rive">
                                    <Rive
                                    src={plusAnimation}
                                    stateMachines="State Machine 1"/>
                                </div>

                                <div className="logo-animation-price">$2,000</div>
                            </div>
                            <ul>
                                <li>Everything in Starter</li>
                                <li><span className="tooltip">Lottie<span className="tooltiptext">Lottie is a library that renders animations in real-time, allowing for high-quality, scalable, and interactive animations on websites and apps.</span></span> Export for Web</li>
                                <li>1 Additional Revision</li>
                            </ul>
                        </div>
                        <div className="logo-animation-premium logo-animation-package">
                            <div className="package-title">
                                <h2>Premium</h2>
                                <div className="logo-animation-price premium">$3,000</div>
                            </div>
                            <ul>
                                <li>Everything in Starter</li>
                                <li><span className="tooltip rive-tooltip">Rive<span className="tooltiptext">Rive is a powerful tool for creating and integrating real-time interactive animations into websites and apps, enhancing user engagement.</span></span> Animation</li>
                                <li><span className="tooltip">Long and Short Version<span className="tooltiptext">We create a long and short version of your logo animation. Typically for testimonials and social media respectively.</span></span></li>
                                <li>1 Additional Revision</li>
                            </ul>
                        </div>
                    </div>

                    <a className="form-below" href="#airtable-form">
                        <span>Get Started Below</span>
                    </a>

                    <div className="logo-animation-faq">
                        <h2>FAQ</h2>
                        <div className="faq-group" id="faq-rive">
                            <div className="faq-question">What is Rive?</div>

                            <div className="faq-answer">Rive is an up and coming animation tool for interactive animation for web and apps. Their render engine is extremely efficient and fast giving them the best playback currently available for web playback of animations while keeping a tiny file size. See more in their <a href="https://rive.app/">website</a>.</div>
                        </div>

                        <div className="faq-group" id="faq-lottie">
                            <div className="faq-question">What is Lottie?</div>

                            <div className="faq-answer">Before Rive came on the scene there was Lottie. It is a lightweight way to play animations from After Effects or Figma in a web browser. Though it is older than Rive, it's adoption has been slower. Still it is a powerful tool for certain situations where interaction isn't as important. When you want the animation to react to user input, Rive is the better solution. See more at LottieFiles' <a href="https://lottiefiles.com">website</a>.</div>
                        </div>
                    </div>
                    <iframe className="airtable-contact" id="airtable-form" src="https://airtable.com/embed/appfoF4P9dqnxThke/pagAIyZwaYcrxReoj/form"></iframe>
                </div>
            </div>
        )
        return logoAnimations
    }
}

export default LogoAnimations