import React, { Component } from "react"
import Hls from "hls.js"

import PlayPauseButton from "./play-pause-button.jsx"
import RivePlayPauseButton from "./rive-play-pause-btn.jsx"

const videoFile = new URL("../video/Reel 2024.mp4", import.meta.url)
const thumbnail = new URL("../video/Reel 2024 - Thumbnail.jpg", import.meta.url)

class HeroVideoPlayer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isPlaying: true, // Add state to manage play/pause
        }

        // Bind methods
        this.handlePlayPause = this.handlePlayPause.bind(this)

        // Create a ref to access the video element
        this.videoRef = React.createRef()
    }

    // Play/Pause video
    handlePlayPause() {
        const video = this.videoRef.current; // Access video element via ref
        const { isPlaying } = this.state; // Destructure state

        if (isPlaying) {
            video.pause();
        } else {
            video.play();
        }

        // Toggle the state of isPlaying
        this.setState({ isPlaying: !isPlaying });
    }



    render() {
        const { clipPath, isPlaying } = this.state

        const heroVideoPlayer = (
            <div className="hero-video-blur">
                <div className="hero-video-player">
                    <video 
                        ref={this.videoRef}
                        width="1920px" 
                        height="1080px" 
                        className="video-bg"
                        id="vid" 
                        poster={thumbnail} 
                        muted 
                        autoPlay 
                        loop 
                        playsInline 
                        style={{clipPath: this.clipPath}} 
                        >
                            <source src={videoFile} type="video/mp4"></source>
                    </video>

                    {/* Play/Pause Button */}
                    {/* <PlayPauseButton isPlaying={isPlaying} handlePlayPause={this.handlePlayPause} /> */}
                    <RivePlayPauseButton isPlaying={isPlaying} handlePlayPause={this.handlePlayPause} />
                </div>
            </div>
            
        )
        return heroVideoPlayer
    }
}

export default HeroVideoPlayer