import React, { Component } from "react"
import Header from "./header.jsx"
import TypingCodeEffect from "./components/TypingCodeEffect.jsx"

class Code extends Component {
    render() {
        const contactForm = (
            <div>
                <Header />
                <div className="page-body product code">
                    <div className="title-text">
                        <TypingCodeEffect />
                    </div>
                    <div className="description-text">
                        <p>My love for coding came from my excitement for learning and being able to build the exact tools I need for a task. For clients I create tailored coding solutions that fit their needs. Whether it’s building custom tools to streamline business operations, developing reusable templates for motion design, or writing code across a wide range of platforms, I’ve got you covered. With expertise in a variety of programming languages spanning web, mobile, and desktop, I want to help solve your unique probelm. Let’s build something together.</p>
                    </div>
                    <div className="centered-banner-text">
                        More Coming Soon...
                    </div>
                </div>
            </div>
        )
        return contactForm
    }
}

export default Code