import React from 'react';
// import './PlayPauseButton.css';

const PlayPauseButton = ({ isPlaying, handlePlayPause }) => {
  return (
    <button className="play-pause-btn" onClick={handlePlayPause}>
      {isPlaying ? (
        // Pause icon
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor" // Use currentColor
        >
          <path d="M6 19h4V5H6v14zM14 5v14h4V5h-4z" />
        </svg>
      ) : (
        // Play icon
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor" // Use currentColor
        >
          <path d="M8 5v14l11-7z" />
        </svg>
      )}
    </button>
  );
};

export default PlayPauseButton;