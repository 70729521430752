import React, { Component } from "react"
import * as ReactDOMClient from "react-dom/client"
import { BrowserRouter, Route, Routes, Switch } from "react-router-dom"
import Home from "./home.jsx"
import Work from "./work.jsx"
import Film from "./film.jsx"
import Animation from "./animation.jsx"
import MotionTemplates from "./motion-templates.jsx"
import Code from "./code.jsx"
import About from "./about.jsx"
import Contact from "./contact.jsx"
import LogoAnimations from "./logo-animations.jsx"
import Error from "./error.jsx"

class App extends Component {
    render() {
        const app = (
            <div>
                <BrowserRouter>
                    <Routes>
                        <Route index path='/' element={<Home />} />
                        <Route path='work' element={<Work />} />
                        <Route path='film' element={<Film />} />
                        <Route path='animation' element={<Animation />} />
                        <Route path='motion-templates' element={<MotionTemplates />} />
                        <Route path='code' element={<Code />} />
                        <Route path='about' element={<About />} />
                        <Route path='contact' element={<Contact />} />
                        <Route path="logo-animations" element={<LogoAnimations />} />
                        <Route path='*' element={<Error />} />
                    </Routes>
                </BrowserRouter>
            </div>
        )
        return app
    }
}


const root = ReactDOMClient.createRoot(document.getElementById("root"))
root.render(<App />)
