import React, { Component } from "react"
import { BrowserRouter, Route, Routes, Switch, Link, Outlet, NavLink } from "react-router-dom"
import GalleryItem from "./gallery-item.jsx"

const ytSettings = "?autoplay=0&mute=1&hd=1"

// Redeemer Midland
const dusty = "https://www.youtube.com/embed/vE5NYrNrog0" + ytSettings
const allHailKingJesus = "https://www.youtube.com/embed/6wId6HXnHqw" + ytSettings
const takeCourage = "https://www.youtube.com/embed/VkrAgvrsCDg" + ytSettings
const messiah = "https://www.youtube.com/embed/e-kbEeChZ2U" + ytSettings
const community = "https://www.youtube.com/embed/KY2kSQBciBI" + ytSettings


// Butler Bros
const landscape = "https://www.youtube.com/embed/M5HNy9EQKVw" + ytSettings

// Reel
const reel = new URL("./video/Reel 2023.mp4", import.meta.url)
const reelThumbnail = new URL("./video/Reel 2023_thumbnail.jpg", import.meta.url)
var urls = []

// MTX
const darkModeAnimation = "https://www.youtube.com/embed/KQhplkaPS7o" + ytSettings
const vocalSynths = "https://www.youtube.com/embed/V_4aAGU2QjU" + ytSettings

// Stone
const logoAnimationsVideo = new URL("./video/Logo Animations 2024 - v02.mp4", import.meta.url)
const logoAnimationsThumbnail = new URL("./video/Logo Animations 2024 - v02 - Thumbnail.jpeg", import.meta.url)
const becauseHeFirstLovedUs = "https://player.vimeo.com/video/288762752?h=d317c76768"
const fullnessOfJoy = "https://player.vimeo.com/video/272959897?h=c1dfcf9aa8"
const aGoodDream = "https://player.vimeo.com/video/310151553?h=f560c0faf9"
const artfullyCreated = "https://player.vimeo.com/video/384864541?h=460e77492c"

// Student
const hoverBike = "https://player.vimeo.com/video/93702765?h=96f7651a0e"

class Gallery extends Component {

    galleryItems() {
        var items = []
        for (let i = 0; i < urls.length; i++) {

        }
    }

    render() {
        const myElement = (
            <div className="gallery">
                <GalleryItem videoFilePath={landscape} videoType="youtube" myPosition="Animator"></GalleryItem>
                <GalleryItem videoFilePath={dusty} videoType="youtube" myPosition="Camera-Op | Editor"></GalleryItem>
                <GalleryItem videoFilePath={becauseHeFirstLovedUs} videoType="vimeo" myPosition="Director | Editor"></GalleryItem>
                <GalleryItem videoFilePath={darkModeAnimation} videoType="youtube" myPosition="Animator"></GalleryItem>
                <GalleryItem videoFilePath={reel} thumbnailPath={reelThumbnail} videoType="local" title="Reel" myPosition="Various"></GalleryItem>
                <GalleryItem videoFilePath={allHailKingJesus} videoType="youtube" myPosition="Director | Camera-Op | Editor"></GalleryItem>
                {/* <GalleryItem videoFilePath={vocalSynths} videoType="youtube"></GalleryItem> */}
                <GalleryItem videoFilePath={community} videoType="youtube" myPosition="Editor"></GalleryItem>
                <GalleryItem videoFilePath={takeCourage} videoType="youtube" myPosition="Director | Camera-Op | Editor"></GalleryItem>
                <GalleryItem videoFilePath={messiah} videoType="youtube" myPosition="Director | Camera-Op | Editor"></GalleryItem>
                <GalleryItem videoFilePath={logoAnimationsVideo} thumbnailPath={logoAnimationsThumbnail} videoType="local" title="Logo Animations" myPosition="Animator" clickThroughLink="/logo-animations#" clickThroughText="Go to Page"></GalleryItem>
                <GalleryItem videoFilePath={fullnessOfJoy} videoType="vimeo" myPosition="Director | Camera-Op | Editor"></GalleryItem>
                <GalleryItem videoFilePath={aGoodDream} videoType="vimeo" myPosition="Director | Camera-Op | Editor"></GalleryItem>
                <GalleryItem videoFilePath={hoverBike} videoType="vimeo" myPosition="Animator"></GalleryItem>
            </div>
        )
        return myElement
    }
}

export default Gallery