import React, { Component } from "react"
import Header from "./header.jsx"

class Contact extends Component {
    render() {
        const contactForm = (
            <div className="contact">
                <Header />
                <iframe className="airtable-contact" src="https://airtable.com/embed/shrv8aoJOA6iIZ2sY?backgroundColor=cyan"></iframe>
            </div>
        )
        return contactForm
    }
}

export default Contact